import { createContext, useContext } from "react";
import { NextRouter, useRouter } from "next/router";
import useSWR from "swr";
import useAuthFetch from "@hooks/use-auth-fetch";
import { PermissionsResponse } from "@utils/sesamy-token";
import { flattenQueryStringKey } from "@utils/query-string";
import { Currency } from "@type-defs/currency";

export type Vendor = {
  logo?: string;
  name?: string;
  vendorId: string;
  currency?: Currency;
  siteUrl?: string;
  country?: string;
};

type VendorListResponse = {
  data: Vendor[];
};

const PERMISSIONS_API = `${process.env.NEXT_PUBLIC_STATS_API}/profile/me/permissions/`;

type ProviderProps = {
  children: React.ReactNode;
};

type VendorContentType = {
  vendors?: string[];
  selectedVendor?: string;
  selectedVendorNew?: string;
  getVendorSettings: (vendor?: string) => Vendor | undefined;
  getVendorStyles: () => any;
};

const VendorContentContext = createContext<VendorContentType>({
  getVendorSettings: () => undefined,
  getVendorStyles: () => undefined,
});

const getVendor = (router: NextRouter) => {
  const vendor = flattenQueryStringKey(router.query, "vendor");

  return vendor;
};

export const VendorContentProvider = ({ children }: ProviderProps) => {
  const router = useRouter();
  const { fetcher, isReady } = useAuthFetch();
  const { data } = useSWR<PermissionsResponse>(
    isReady && PERMISSIONS_API,
    fetcher,
  );

  const { data: vendorListData } = useSWR<VendorListResponse>(
    isReady && `${process.env.NEXT_PUBLIC_STATS_API}/stats/vendorsList`,
    fetcher,
  );

  const { data: vendorStyles } = useSWR<VendorListResponse>(
    isReady &&
      getVendor(router) &&
      `${process.env.NEXT_PUBLIC_STATS_API}/profile/vendors/${getVendor(router)}/style`,
    fetcher,
  );

  const vendorList = vendorListData?.data;

  const correctedVendorList =
    vendorList &&
    vendorList.map((p) => {
      if (p.vendorId === "thirdearstudio") {
        return { ...p, vendorId: "thirdearstudios" };
      }
      return p;
    });

  // TODO - when can we remove these thirdearstudios fixes
  const correctedVendors = data?.permissions.map((p) => {
    if (p.vendorId === "thirdearstudio") {
      return { ...p, vendorId: "thirdearstudios" };
    }
    return p;
  });

  let vendors = correctedVendors?.map((p) => p.vendorId);

  // this is getting a bit hacky...
  if (vendors?.includes("*")) {
    if (vendorList) {
      vendors = vendorList.map((v) => v.vendorId);
    }
  }

  // sort vendors alphabetically
  vendors?.sort((a, b) => a.localeCompare(b));

  // return like this for backwards compatibility
  let selectedVendor = getVendor(router);

  const getVendorSettings = (vendor?: string) => {
    if (!correctedVendorList) return undefined; // not sure what to do here...  as it's loading...
    const vendorSettings = correctedVendorList.find(
      (v) => v.vendorId === vendor,
    );
    return vendorSettings;
  };

  const getVendorStyles = () => {
    if (!correctedVendorList) return {};

    return vendorStyles || {};
  };

  // we also need the "correct" name to fetch the sesamy token
  let selectedVendorNew = selectedVendor;
  if (selectedVendor === "thirdearstudios") {
    selectedVendorNew = "thirdearstudio";
  }

  return (
    <VendorContentContext.Provider
      value={{
        selectedVendor,
        selectedVendorNew,
        vendors,
        getVendorSettings,
        getVendorStyles,
      }}
    >
      {children}
    </VendorContentContext.Provider>
  );
};

export const useVendorContext = () => useContext(VendorContentContext);
